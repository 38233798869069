import { useGetUserQuery, useUser } from 'common/services/useUser'
import { useEffect, useRef } from 'react'
import { getApi } from 'common/api'
import { useRedirect } from 'common/services/useRedirect'

export default function (runOnce?: boolean) {
  const { data: user } = useUser({})
  const { data: data2 } = useGetUserQuery({})
  const ref = useRef(runOnce ? false : user ? true : false)
  const { redirect } = useRedirect()
  useEffect(() => {
    if (user && !ref.current) {
      getApi().loginRedirect(user, redirect?.url)
    }
    ref.current = !!user
  }, [user])
  return { user }
}
