import { FC } from 'react'
import classNames from 'classnames'

type ThemeType = {
  icon: string
  title: string
  theme: 'yellow' | 'turquoise' | 'pink'
}

const Theme: FC<ThemeType> = ({ children, icon, theme, title }) => {
  return (
    <div
      style={{ color: '#4D4951', fontSize: 20, lineHeight: '30px' }}
      className={classNames('h-100 rounded-lg p-4 text-', `bg-${theme}`)}
    >
      <div className={'fw-bold mb-2 mt-2'}>
        {title}
        {icon}
      </div>
      {children}
    </div>
  )
}

export default Theme
