import React from 'react'
import 'project/polyfill'
import Head from 'next/head'
import { useRouter } from 'next/router'

import Icon from 'components/Icon'
import Button from 'components/base/forms/Button'
import PageContainer from 'components/PageContainer'
import { NextPageWithLayout } from '../types/nextPageWithLayout'
import useLoggedInRedirect from 'common/hooks/useLoggedInRedirect'
import { useUser } from 'common/services/useUser'
import Loader from 'components/Loader'
import hasFeature from 'common/hasFeature'
import AccountPage from './account'
import { NextSeoProps } from 'next-seo'
import DownloadBtn from 'components/DownloadAppBtn'
import Feature from 'components/Feature'

export type HomePageType = {
  seoProps?: NextSeoProps
  image?: string
  title?: string
}
const HomePage: NextPageWithLayout<HomePageType> = ({
  image,
  seoProps,
  title,
}) => {
  if (!image) {
    useLoggedInRedirect(true)
  }
  const { data, isLoading } = useUser({})
  const router = useRouter()
  if (data && !image && hasFeature('live')) {
    return <AccountPage />
  }
  return (
    <main>
      <PageContainer seoProps={seoProps}>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <div
                className='d-flex align-items-center'
                style={{
                  minHeight: 'calc(100vh - 68px)',
                }}
              >
                <div>
                  <div className='flex-row gap-4'>
                    <Icon height={45} width={41} name='bbbLogo' />
                    <h3 className='mb-0 fw-normal font-brand'>
                      {title || 'BUILT BY BECKY'}
                    </h3>
                  </div>
                  <>
                    {image ? (
                      <>
                        <img className='rounded mt-4' src={image} />
                      </>
                    ) : (
                      <div>
                        <h3 className='mt-6 mt-sm-11 text-semibold mb-2'>
                          We’re helping <br /> women feel amazing <br /> at any
                          age
                        </h3>
                        <h6 className='text-regular'>
                          Join our amazing community of women in improving your
                          <br />
                          physical and mental health, all from the comfort of
                          your
                          <br /> own home.
                        </h6>
                        <Button
                          aria-label='join-now'
                          className='mt-6'
                          theme='secondary'
                          onClick={() => {
                            router.push('/auth/signup')
                          }}
                        >
                          <span className='px-12'>Join Now</span>
                        </Button>
                      </div>
                    )}
                  </>
                </div>
              </div>

              <div className='overflow-auto scroll-hover'>
                <h3 className='mt-md-4 mb-md-6 fw-semibold'>Our App</h3>
                <div className='flex-row screenshots gap-4 flex-nowrap'>
                  <img src={'/images/app/1.png'} />
                  <img src={'/images/app/2.png'} />
                  <img src={'/images/app/3.png'} />
                  <img src={'/images/app/4.png'} />
                  <img src={'/images/app/5.png'} />
                </div>
              </div>
              <div className='flex-row gap-4 mt-md-8 mt-4 justify-content-center'>
                <DownloadBtn store='google' />
                <DownloadBtn store='apple' />
              </div>
              <div className='mt-md-20 mt-4'>
                <h3 className='mt-md-20 mt-4 mb-4 fw-semibold'>Features</h3>

                <div className='row'>
                  <div className='col-md-4 mb-4'>
                    <Feature
                      theme='turquoise'
                      title='Working out'
                      icon={
                        <svg
                          className='ms-1'
                          width={20}
                          xmlns='http://www.w3.org/2000/svg'
                          viewBox='0 0 640 512'
                        >
                          <path
                            fill={'#4D4951'}
                            d='M96 64c0-17.7 14.3-32 32-32h32c17.7 0 32 14.3 32 32v384c0 17.7-14.3 32-32 32h-32c-17.7 0-32-14.3-32-32v-64H64c-17.7 0-32-14.3-32-32v-64c-17.7 0-32-14.3-32-32s14.3-32 32-32v-64c0-17.7 14.3-32 32-32h32V64zm448 0v64h32c17.7 0 32 14.3 32 32v64c17.7 0 32 14.3 32 32s-14.3 32-32 32v64c0 17.7-14.3 32-32 32h-32v64c0 17.7-14.3 32-32 32h-32c-17.7 0-32-14.3-32-32V64c0-17.7 14.3-32 32-32h32c17.7 0 32 14.3 32 32zM416 224v64H224v-64h192z'
                          />
                        </svg>
                      }
                    >
                      <p>
                        Enjoy my ever growing list of amazing workouts or join
                        one of my scheduled programs.
                      </p>
                      Join periodic challenges throughout the year and workout
                      with me!
                    </Feature>
                  </div>
                  <div className='col-md-4 mb-4'>
                    <Feature
                      theme='pink'
                      title='Our Community'
                      icon={
                        <svg
                          className='ms-1'
                          width={20}
                          xmlns='http://www.w3.org/2000/svg'
                          viewBox='0 0 640 512'
                        >
                          <path
                            fill={'#4D4951'}
                            d='M96 128a128 128 0 1 1 256 0 128 128 0 1 1-256 0zM0 482.3C0 383.8 79.8 304 178.3 304h91.4c98.5 0 178.3 79.8 178.3 178.3 0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3zM609.3 512H471.4c5.4-9.4 8.6-20.3 8.6-32v-8c0-60.7-27.1-115.2-69.8-151.8 2.4-.1 4.7-.2 7.1-.2h61.4c89.1 0 161.3 72.2 161.3 161.3 0 17-13.8 30.7-30.7 30.7zM432 256c-31 0-59-12.6-79.3-32.9 19.7-26.6 31.3-59.5 31.3-95.1 0-26.8-6.6-52.1-18.3-74.3C384.3 40.1 407.2 32 432 32c61.9 0 112 50.1 112 112s-50.1 112-112 112z'
                          />
                        </svg>
                      }
                    >
                      <p>
                        Join our amazing workout community and share your
                        journey in the BBB Ladies Lounge. Share your progress ,
                        thoughts and workout tips with other members.
                      </p>
                    </Feature>
                  </div>
                  <div className='col-md-4 mb-4'>
                    <Feature
                      theme='primary'
                      title='Nutrition'
                      icon={
                        <svg
                          width={20}
                          viewBox='0 0 48 40'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M7.75 2.16423C7.75 4.20151 8.57031 5.13942 9.77344 6.47711L9.80469 6.50786C11.0937 7.93011 12.75 9.7675 12.75 13.2347C12.75 14.2572 11.9141 15.0798 10.875 15.0798C9.83594 15.0798 9 14.2572 9 13.2347C9 11.1974 8.17969 10.2595 6.97656 8.92184L6.94531 8.89108C5.65625 7.46884 4 5.63144 4 2.16423C4 1.14175 4.83594 0.319153 5.875 0.319153C6.91406 0.319153 7.75 1.14175 7.75 2.16423ZM16.5 2.16423C16.5 4.20151 17.3203 5.13942 18.5234 6.47711L18.5547 6.50786C19.8437 7.93011 21.5 9.7675 21.5 13.2347C21.5 14.2572 20.6641 15.0798 19.625 15.0798C18.5859 15.0798 17.75 14.2572 17.75 13.2347C17.75 11.1974 16.9297 10.2595 15.7266 8.92184L15.6953 8.89108C14.4062 7.46884 12.75 5.63144 12.75 2.16423C12.75 1.14175 13.5859 0.319153 14.625 0.319153C15.6641 0.319153 16.5 1.14175 16.5 2.16423ZM32.75 8.92952C36.7109 8.92952 40.0625 11.5203 41.1406 15.0798H24.3594C25.4375 11.5203 28.7812 8.92952 32.75 8.92952ZM5.07031 24.9202H2.75C1.36719 24.9202 0.25 23.8209 0.25 22.4601C0.25 21.0994 1.36719 20 2.75 20H4.14844C4.13281 18.6546 5.24219 17.5399 6.625 17.5399H41.375C42.7578 17.5399 43.8672 18.6546 43.8516 20H45.25C46.6328 20 47.75 21.0994 47.75 22.4601C47.75 23.8209 46.6328 24.9202 45.25 24.9202H42.9297C41.3203 29.8404 37.7656 33.8919 33.1875 36.2367L33.0937 36.9824C32.8984 38.52 31.5703 39.6732 29.9922 39.6732H18.0078C16.4297 39.6732 15.1016 38.52 14.9062 36.9824L14.8125 36.2367C10.2344 33.8919 6.67969 29.8404 5.07031 24.9202Z'
                            fill='#4D4951'
                          />
                        </svg>
                      }
                    >
                      <p>
                        Browse my favourite recipes, nutritional information
                        then get cooking! I provide ingredients and quantity
                        control information as well as a step by step guide to
                        cooking some amazing healthy meals!{' '}
                      </p>
                    </Feature>
                  </div>
                </div>
                <div>
                  <h6 className='mt-6'>Even more features!</h6>
                  <ul>
                    <li>
                      Workout your way with your own{' '}
                      <strong>customisable schedule</strong>.
                    </li>
                    <li>
                      Enjoy seeing your progress with{' '}
                      <strong>photos and measurement tracking</strong> on your
                      BBB member profile.
                    </li>
                    <li>
                      <strong>
                        Water tracking, user progressed or auto progressed
                        workouts, screen casting
                      </strong>{' '}
                      and loads more!
                    </li>
                  </ul>
                </div>
                <div className='flex-row my-6 justify-content-center'>
                  <Button
                    aria-label='join-now'
                    className='mt-6'
                    theme='secondary'
                    onClick={() => {
                      router.push('/auth/signup')
                    }}
                  >
                    <span className='px-12'>Join Now</span>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageContainer>
    </main>
  )
}

export default HomePage
